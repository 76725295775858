.m-searchBox {
    position: relative;

    @include media-down(m) {
        width: 80%;
    }

    @include media-up(l) {
        width: 30%;
    }

    .m-inputGroup {
        input {
            border-radius: 8px;
        }
        .a-icon-box {
            position: absolute;
            left: unset;
            top: 50%;
            right: 2%;
            transform: translateY(-50%);
            cursor: pointer;

            @include media-up(l) {
                left: auto;
            }
        }
    }
}
