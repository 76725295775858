$white: #fff !default;
$black: #000 !default;

$blue: #3a84f9;
$blue-dark: #25272c;
$blue-light: rgba(244, 245, 252, 0.2);
$purple-blue: #162059;
$purple-blue-light: #165cc4;
$light-blue: #6c78b2;
$light-blue-2: #e1dfec;

$dark-blue: #182237;
$dark-purple: #071a53;
$purple-light: #263768;
$light-purple: #c5c9e1;

$gray-12: #fafaff;
$gray-13: #ecf0f5;
$gray-14: #929bb3;
$gray-15: #f4f4fc;
$gray-16: #ced3e0;
$gray-17: #6e7584;
$gray-200: #999;

$green: #6ec486;
$light-green: #f0f9f2;

$red: #f16f6f;
$light-red: #fff2f2;

$error-color: #ff5e5a;
$success-color: #00bf9c;
$default-color: #383f52;
// Set a specific jump point for requesting color jumps
$color-interval: 8% !default;

// The yiq lightness value that determines when the lightness of color changes from "dark" to "light". Acceptable values are between 0 and 255.
$contrast-threshold: 150 !default;

$box-shadow: 0 14px 30px -15px rgba(81, 56, 238, 0.5) !default;
$button-shadow: inset 0 -3px 0 0 rgba(23, 7, 83, 0.5);
$working-shadow: 14px 12px 30px -15px rgb(72 72 72 / 30%);
