.m-reports {
    &__input {
        margin: 30px -15px;
    }

    &__header {
        margin-bottom: 30px;
    }
    &__type {
        display: flex;
        .m-dateTimePicker {
            width: 48.5%;
            margin-bottom: 15px;
            &:nth-child(2) {
                margin-left: 30px;
            }
        }
    }
    .m-chart {
        height: 550px;
        width: 100%;
        &__title {
            padding: 10px 20px 7px;
            color: $white;
            background-color: $purple-blue-light;
            border-radius: 4px 4px 0 0;
            span {
                font-size: 16px;
            }
        }

        &__customTooltip {
            padding: 10px 15px;
            border-radius: 4px;
            border: 1px solid $purple-blue-light;

            p {
                margin: 0;
            }
        }

        .recharts-responsive-container {
            background-color: $white;
            box-shadow: 14px 12px 30px -15px rgb(72 72 72 / 10%),
                -14px 12px 30px -15px rgb(72 72 72 / 10%);

            .recharts-legend-wrapper {
                padding-top: 15px;

                li {
                    margin-right: 30px !important;

                    svg {
                        margin-right: 10px !important;
                        margin-bottom: 3px !important;
                    }

                    span {
                    }
                }
            }
        }
    }

    .m-export {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        margin-top: 50px;
        color: $white;

        &__box {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            padding: 7px 15px;
            background-color: $purple-blue-light;
            border-radius: 4px;
            cursor: pointer;
            span {
                margin-top: 3px;
            }
        }
    }
}
