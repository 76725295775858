.a-badge {
    display: inline-block;
    width: 100px;
    padding: 8px 18px;
    text-align: center;
    border-radius: 15px;
    font-size: 14px;
    cursor: pointer;

    &.-active {
        background-color: $light-green;
        color: $green;
    }
    
    &.-inactive {
        background-color: $light-red;
        color: $red;
    }
}
