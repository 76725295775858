.m-progress {
    margin: 30px 0;
    padding: 20px 30px;
    background-color: $white;
    border-radius: 8px;

    span {
        padding-bottom: 5px;
    }
    &__bar {
        height: 10px;
        width: 100%;
        background-color: #feb32d;
        border-radius: 50px;

        &--percantage {
            height: 100%;
            border-radius: 50px;
        }
    }

    &__day {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
}
