.m-map {
    position: relative;
    margin-top: 20px;
    margin-bottom: 30px;

    &__city {
        @include media-up(l) {
            margin-top: 40px;
        }
    }

    &__addressInput {
        display: flex;
        align-items: center;
        font-weight: 600;
        font-size: 16px;
        letter-spacing: -0.2px;
        line-height: 22px;
        padding-right: 10px;
        margin-bottom: 20px;

        p {
            margin: 0;
        }

        .a-icon-box {
            position: absolute;
            top: 50%;
            right: 2% !important;
            left: auto;
            width: auto;
            padding: 5px;
            background-color: $purple-blue-light;
            border-radius: 8px;
            box-shadow: $box-shadow;
            transform: translateY(-50%);
            cursor: pointer;
        }

        input {
            width: 100%;
            padding: 12px 0 10px 20px;
            border-radius: 8px;
        }
    }

    &__addressIconContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 10px;
        height: 34px;
        width: 34px;
        min-height: 34px;
        min-width: 34px;
        border-radius: 50%;
        background-color: $white;

        svg {
            min-width: 12px;
            min-height: 14px;
        }
    }

    &__googleMapWrapper {
        height: 365px;
        padding: 10px;
        background-color: $white;
        border: 1px solid $gray-13;

        &,
        & > div {
            border-radius: 5px;
        }
    }

    &__removeMarker {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 44px;
        margin-top: 15px;
        border-radius: 5px;
        background-color: rgba($red, 0.1);
        color: $red;
        font-size: 13px;
        line-height: 15px;
        font-weight: 600;
        border: 0;
        cursor: pointer;
        transition: background-color 0.3s;

        &:hover {
            background-color: rgba($red, 0.2);
            transition: 0.3s;
        }

        &:focus {
            outline: 0;
        }

        &.-viewMore {
            background-color: $black;
            color: $white;

            &:hover {
                background-color: darken($black, 10%);
                transition: 0.3s;
            }
        }
    }

    &__addressInput {
        position: absolute;
        z-index: 1;
        bottom: 20px;
        left: 35px;
        width: 300px;

        input {
            border: 1px solid $purple-blue-light;
        }

        .confirm {
            cursor: pointer;

            .a-icon-box {
                right: 5% !important;
                border: 1px solid $purple-blue-light;
                transition: 0.3s;

                &:hover {
                    background-color: $white;
                    transition: 0.3s;

                    svg {
                        stroke: $purple-blue-light;
                    }
                }
            }
        }

        .a-text {
            padding-right: 40px;
        }
    }

    .gm-style-iw {
        width: 280px;
        padding: 12px 20px;
    }

    .gm-ui-hover-effect {
        transform: translate(-5px, 5px) scale(1.25);
    }
}
