.m-email {
    .m-inputGroup {
        &.-icon {
            position: relative;
            .a-icon-box {
                right: 12px;
                top: 65%;
                padding: 0;
                background-color: transparent;
                box-shadow: none;
                svg {
                    stroke: $light-purple;
                }
            }

            input {
                &:focus {
                    ~ .a-icon-box {
                        svg {
                            stroke: $purple-blue-light;
                        }
                    }
                }
            }
        }
    }
}
