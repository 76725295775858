.m-fileDragAndDrop {
    height: 145px;
    margin-bottom: 45px;
    .a-file {
        display: flex;
        height: 100%;
        align-items: center;
        margin-bottom: 25px;
        padding: 15px;
        background-color: $white;
        border: 1px dashed $light-blue-2;
        font-size: 14px;
        transition: 0.3s;
        cursor: pointer;

        &:hover,
        &:focus {
            border: 1px dashed $purple-blue-light;
            transition: 0.3s;
        }

        p {
            margin: 5px 0 0 10px;
            font-size: 13px;
        }

        .imageContainer {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 60px;
            width: 60px;
            margin: 0 10px;

            .image {
                height: 100%;
                width: 100%;
                object-fit: cover;
                border-radius: 50%;
            }
        }
    }
}
