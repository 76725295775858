.m-multiselectGroup {
    width: 100%;
    margin-bottom: 22px;

    &__container {
        position: relative;

        ~ .m-inputGroup {
            &__error {
                &.-active {
                    bottom: -2px;
                    left: 15px;
                }
            }
        }
    }

    ::-webkit-scrollbar {
        width: 6px;
    }

    ::-webkit-scrollbar-track {
        background-color: $gray-13;
        border-radius: 4px;
        opacity: 0.5;
    }

    ::-webkit-scrollbar-thumb {
        background: $purple-blue-light;
        border-radius: 4px;
    }

    &__multiselect {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 100%;
        padding: 3px 60px 0 7px;
        border: 1px solid $light-blue-2;
        background-color: $white;
        border-radius: 4px;
        outline: 0;
        color: $light-blue;
        font-size: 14px;
        transition: border-color 0.3s, background-color 0.3s;
        filter: none;

        .a-chevron {
            position: absolute;
            top: 45%;
            right: 20px;
        }

        &:invalid {
            box-shadow: none;
        }

        &:focus {
            background-color: $white;
            border-color: $purple-blue-light;

            ~ svg {
                stroke: $purple-blue-light;
            }
        }

        &.-open {
            border-color: $purple-blue-light;
            outline: 0;

            .a-chevron {
                transform: rotate(225deg);
                margin-top: 2px;
            }
        }
    }

    &__removeAll {
        height: 20px;
        width: 20px;
        position: absolute;
        right: 30px;
        top: 50%;
        transform: translateY(-50%);
        display: flex;
        margin: 0 5px;
        padding: 2px;
        cursor: pointer;
        // border: 1px solid $light-blue;
        border-radius: 4px;
        background-color: $purple-blue-light;

        &::before,
        &::after {
            content: "";
            position: absolute;
            height: 1px;
            width: 85%;
            top: 50%;
            background: $white;
        }

        &::before {
            transform: translateY(-50%) rotate(-45deg);
        }

        &::after {
            transform: translateY(-50%) rotate(45deg);
        }
    }

    &__items {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        min-height: 44px;
        padding: 2.5px;
    }

    &__placeholder {
        position: absolute;
        color: $gray-14;
        line-height: 16px;
    }

    &__item {
        background-color: $purple-blue-light;
        border: 1px solid $purple-blue-light;
        border-radius: 5px;
        padding: 5px 10px;
        display: flex;
        height: 34px;
        align-items: center;
        justify-content: space-between;
        margin: 3px 5px 4px;
        color: $white;
        animation-name: show;
        animation-duration: 0.3s;

        .label {
            font-size: 13px;
            line-height: 16px;
            letter-spacing: -0.2px;
            margin-right: 15px;
        }

        @keyframes show {
            from {
                transform: scale(0);
            }

            to {
                transform: scale(1);
            }
        }
    }

    &__removeItem {
        height: 10px;
        width: 10px;
        position: relative;
        display: flex;
        cursor: pointer;

        &::before,
        &::after {
            content: "";
            position: absolute;
            height: 1px;
            width: 100%;
            top: 50%;
            background: $white;
        }

        &::before {
            transform: translateY(-50%) rotate(-45deg);
        }

        &::after {
            transform: translateY(-50%) rotate(45deg);
        }
    }

    &__searchItem {
        background-color: transparent;
        color: $purple-blue-light;
        display: flex;
    }

    &__search {
        outline: none;
        align-self: center;
        min-width: 20px;
        max-width: 300px;
        font-size: 14px;
        color: $dark-blue;

        overflow-x: hidden;

        input {
            width: 50px;
        }
    }

    &__options {
        position: absolute;
        z-index: 2;
        background-color: $white;
        border: 1px solid $purple-blue-light;
        border-radius: 4px;
        width: 100%;
        opacity: 0;
        bottom: 10px;
        pointer-events: none;
        transform: translateY(100%);
        transition: opacity 0.3s, bottom 0.3s;
        overflow: hidden;
        max-height: 150px;
        overflow-y: auto;

        &.-open {
            opacity: 1;
            pointer-events: initial;
            bottom: -1px;
        }

        ul {
            list-style: none;
            padding: 0;
            margin: 0;

            li {
                position: relative;
                padding: 5px 25px 5px 10px;
                font-size: 14px;
                cursor: pointer;
                word-break: break-all;
                transition: background-color 0.3s;

                &:hover {
                    background-color: rgba($purple-blue-light, 0.1);
                    transition: 0.3s;
                }

                &.-empty {
                    background-color: transparent;
                    cursor: default;
                }

                &.-selected {
                    color: $purple-blue-light;

                    &::after {
                        position: absolute;
                        top: 7px;
                        right: 10px;
                        content: "";
                        transform: rotate(45deg);
                        height: 10px;
                        width: 5px;
                        border-bottom: 1.5px solid currentColor;
                        border-right: 1.5px solid currentColor;
                    }
                }
            }
        }
    }
}
