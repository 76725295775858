.chart {
    position: relative;
    height: 100%;
    margin-bottom: 50px;
    margin-top: 50px;
    &__hideLogo {
        position: absolute;
        background-color: #fff;
        z-index: 9999;
        left: 50;
        bottom: 0;
        width: 400px;
        height: 20px;
    }

    #chartdiv {
        width: 100%;
        height: 50vh;
        margin: 40px auto;

        text {
            text-transform: capitalize;
        }
    }
}

.m-chartHover {
    position: absolute;
    left: -5px;
    bottom: -5px;
    width: 85px;
    height: 55px;
    min-width: 85px;
    min-height: 55px;
    background-color: transparent;
}
