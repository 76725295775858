.m-pagination {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 25px;
    border-radius: 5px;

    @include media-down(m) {
        flex-direction: column;
    }

    .a-pagination {
        width: 100%;
        padding: 20px;
        display: flex;
        justify-content: space-evenly;
    }

    &-selector {
        width: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 5px;
        height: 45px;
        color: $light-blue;
        border-radius: 4px 0 0 4px;

        @include media-down(m) {
            margin-bottom: 15px;
        }

        span {
            display: inline-block;
            margin: 0 10px;

            font-weight: bold;
        }

        .a-icon-box {
            margin-top: -3px;
        }

        &__items {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            border-radius: 8px;
        }
    }

    &-numbers {
        display: flex;
        justify-content: flex-end;
        height: auto;
        padding: 5px;
        background-color: $white;

        .item {
            position: relative;
            display: inline-block;
            width: 35px;
            min-width: 35px;
            height: 35px;
            margin: 0 1px;
            color: $gray-14;
            border-radius: 3px;
            text-align: center;
            line-height: 35px;
            border: none;
            font-size: 1rem;
            cursor: pointer;
            transition: 0.3s;

            &.active {
                background-color: $purple-blue-light;
                color: white;
            }

            &.disabled {
                display: none;
                pointer-events: none;
            }

            .a-icon-box {
                left: unset;
                min-width: 35px;
                min-height: 35px;
                padding: 5px;
            }

            &:hover {
                background-color: rgba($purple-blue-light, $alpha: 0.6);
                color: $white;
                transition: 0.3s;
            }
        }
    }
}
