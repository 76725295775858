.a-button {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $purple-blue-light;
    border: 1px solid rgb(224, 224, 224);
    font-size: 14px;
    font-family: "SofiaPro";
    cursor: pointer;

    &.-icon {
        position: relative;
        display: flex;
        justify-content: center;
        box-shadow: none;
    }
    &.-small {
        margin-left: 20px;
        padding: 12px 10px 9px 20px;
        font-size: 12px;
        border: 1px solid $purple-blue-light;
        transition: 0.3s;
        .a-buttonText {
            transition: 0.3s;

            &.-circle {
                margin-left: 15px;
                margin-top: 3px;
                svg {
                    width: 20px;
                    height: 20px;
                    background-color: rgba($gray-15, 0.2);
                    border: 1px solid transparent;
                    border-radius: 50px;
                    transition: 0.3s;
                }
            }
        }
        &:hover {
            .a-buttonText {
                &.-circle {
                    svg {
                        background-color: $purple-blue-light;
                        border: 1px solid transparent;
                        transition: 0.3s;
                    }
                }
            }
        }
    }
    &.-medium {
        padding: 11px 20px;
        width: 66%;
        font-size: 14px;
    }
    &.-sync {
        color: white;
        border-radius: 8px;
        transition: 0.3s;

        &:hover {
            background-color: #162059;
            box-shadow: $button-shadow;
            transition: 0.3s;
        }
        span {
            svg {
                display: none;
            }
        }
        &.-large {
            padding: 15px 0 10px;
            font-size: 16px;
            transition: 0.3s;

            span {
                svg {
                    display: none;
                }
            }

            @include media-up(l) {
                width: 100%;
            }
        }

        @include media-down(xs) {
            margin-top: 10px;
        }
    }

    &.-primary {
        color: white;
        border-radius: 8px;
        transition: 0.3s;

        &:hover {
            background-color: #162059;
            box-shadow: $button-shadow;
            transition: 0.3s;
        }
        &.-large {
            padding: 15px 0 10px;
            font-size: 16px;
            transition: 0.3s;

            span {
                svg {
                    display: none;
                }
            }

            @include media-up(l) {
                width: 100%;
            }
        }
        @include media-down(xs) {
            margin-top: 10px;
        }
    }
    &.-secondary {
        display: block;
        width: 100%;
        padding: 13px 15px 7px;
        border-radius: 8px;
        color: $purple-blue-light;
        background-color: rgb(56, 121, 238, 0.1);
        border-radius: 4px;
        border: none;
        transition: 0.3s;

        @include media-down(l) {
            width: 100%;
        }

        svg {
            display: none;
        }

        &:hover {
            background-color: $purple-blue-light;
            color: $white;
            transition: 0.3s;
        }
    }

    &__icon {
        width: auto;
        height: auto;
        font-size: 19px;
        font-weight: 500;
    }
    &__content {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        .a-icon-box {
            position: unset;
            transform: unset;
        }
        &.-icon {
            position: relative;
            display: flex;
            justify-content: center;
            box-shadow: none;
        }
        &.-afterText {
            position: relative;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            .a-icon-box {
                position: unset;
                transform: unset;
            }
        }
        .a-buttonText {
            display: block;
            width: auto;
            margin: 2px 10px 0 10px;
            font-size: 16px;
        }
    }
    &.-disabled {
        opacity: 0.4;
        background-color: #333;
    }
    &.-checkMail {
        display: block;
        margin: 20px auto 0;
        padding: 20px 0;
        color: $white;
        border-radius: 25px;
        border: 0;
        font-weight: 500;
        font-size: 1rem;
        letter-spacing: 0;
        cursor: pointer;
    }
    &.-loginButton {
        width: 100%;
        padding: 12px 30px;
        margin: 10px auto;
        border-radius: 25px;
    }
    &.-createUser {
        width: 250px !important;
    }
    &.-changePassword {
        width: 180px;
        box-shadow: none;
        border-radius: 8px;
        color: $white;
    }
    &.-close {
        width: 40px;
        padding-top: 5px;
        text-align: center;
        line-height: 30px;
        border-radius: 5px;
        position: absolute;
        top: 20px;
        right: 20px;

        span {
            display: block;
            font-size: 20px !important;
            color: $white;
        }

        .a-icon-box {
            display: none;
        }
    }
}

@keyframes rotate {
    0% {
        transform: rotate(0);
    }
    100% {
        transform: rotate(180deg);
    }
}
