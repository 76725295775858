.m-table {
    position: relative;
    table-layout: fixed;
    width: 100%;
    margin-top: 30px;

    ::-webkit-scrollbar {
        height: 4px;
    }

    ::-webkit-scrollbar-track {
        background-color: $gray-13;
        border-radius: 4px;
        opacity: 0.5;
    }

    ::-webkit-scrollbar-thumb {
        background: $purple-blue-light;
        border-radius: 4px;
    }

    &__container {
        overflow-x: auto;
    }

    &__container {
        width: 100%;
        border-radius: 8px;
    }

    &__titleIcon {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 15px;
        padding-right: 10px;
    }

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-bottom: 10px;
    }

    table {
        width: 100%;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 0.8rem;
        border-collapse: collapse;

        td,
        th {
            border-bottom: 1px solid $gray-15;
            text-align: left;
        }

        td.m-table__actions {
            text-align: right;
        }

        thead {
            tr {
                background-color: $white;

                th {
                    padding: 12px 0;
                    text-align: left;
                    font-size: 1rem;

                    span {
                        display: inline-block;
                    }

                    &:first-child {
                        padding-left: 10px;
                        padding-right: 10px;

                        @include media-up(m) {
                            padding-right: 0;
                        }
                    }

                    .a-thead-title {
                        max-width: max-content;
                        display: flex;
                        justify-content: center;

                        .a-icon-box {
                            left: -70%;
                            margin-top: -3px;
                            margin-right: 5px;

                            svg {
                                padding: 1px;
                                background-color: $gray-12;
                                border-radius: 4px;
                                transition: 0.3s;
                            }
                        }

                        span {
                            font-family: "SofiaPro", sans-serif;
                            font-weight: 500;
                            letter-spacing: -0.37px;
                            line-height: 20px;
                        }
                    }
                }

                th.sortable {
                    cursor: pointer;
                    @include media-down(m) {
                        padding-right: 26px;
                        padding-left: 17px;
                    }
                }
            }
        }

        tbody {
            cursor: pointer;

            tr {
                background-color: $white;

                &:hover {
                    background-color: rgba($purple-blue-light, 0.05);
                    transition: 0.3s;
                }

                td {
                    padding: 5px;
                    font-size: 14px;

                    .flag {
                        width: 30px;
                        height: 30px;
                    }

                    &:first-child {
                        padding-left: 15px;
                    }

                    //TODO:: ispeglaj ovaj deo sa avatarom i refaktorisi kad Milijana zavrsi komponentu u tabeli
                    .a-avatar {
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;

                        @include media-up(m) {
                            display: flex;
                            align-items: center;
                        }

                        .a-icon-box {
                            display: block;
                        }

                        .a-avatarBox {
                            @include media-down(m) {
                                min-width: 35px;
                            }

                            &__img {
                                @include media-down(m) {
                                    width: 30px;
                                    height: 30px;
                                }
                            }
                        }

                        span {
                            display: inline-block;
                            margin-left: 7px;
                        }
                    }

                    &:before {
                        font-size: 14px;

                        @include media-up(s) {
                            font-size: 16px;
                        }
                    }

                    &:nth-child(3) {
                        text-transform: lowercase;

                        &::first-letter {
                            text-transform: capitalize;
                        }
                    }

                    &:last-child {
                        span {
                            position: relative;
                            display: inline-block;
                            width: 40px;

                            .a-icon-box {
                                cursor: pointer;
                            }
                        }
                    }
                }
            }
        }
    }

    &__actionsContainer {
        position: relative;
        width: 100%;
    }

    &__filters {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 15px;
        width: 100%;

        .m-inputGroup,
        .m-selectGroup,
        .m-dateTimePicker {
            width: 100%;
            margin-right: 15px;
            margin-bottom: 0;

            @include media-up(xl) {
                width: 200px;
            }
        }

        .a-checkbox {
            margin-top: 22px;
        }

        &--reset {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 30px;

            span {
                margin-left: 10px;
                margin-top: 3px;

                &:hover {
                    cursor: pointer;
                }
            }

            &:hover {
                cursor: pointer;
            }
        }
    }

    &__filterButton {
        width: 40px;
    }
}
