.a-loaderContainer {
    position: absolute;
    left: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translateX(-50%);

    @include media-up(l) {
        top: 50%;
        transform: translate(-50%, -50%);
    }
}
