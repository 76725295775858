.m-checkbox {
    margin-bottom: 25px;
    padding: 10px 0;
    transition: 0.3s;

    @include media-up(l) {
        margin-top: 30px;

        &.-camera {
            margin-top: 0;
        }
    }

    &.-user {
        // margin-left: 20px;
        margin-top: 0;
    }

    .a-checkBox {
        width: auto;
        display: flex;
        justify-content: flex-start;

        input {
            display: none;

            &:checked {
                & + label {
                    &::before {
                        background-color: $purple-blue-light;
                        border-color: $purple-blue-light;
                    }
                }
            }
        }

        label {
            display: inline-flex;
            align-items: center;
            cursor: pointer;
            line-height: 16px;

            &::before {
                content: "";
                display: inline-block;
                width: 20px;
                min-width: 20px;
                height: 20px;
                margin-right: 10px;
                border: 1px solid $gray-16;
                border-radius: 3px;
                background-image: url(../../../images/checkWhite.svg);
                background-position: center;
                background-repeat: no-repeat;
                background-color: $white;
                transition: 0.3s;
            }
        }

        span {
            margin-left: 10px;
            font-size: 0.8rem;
            font-weight: 600;
        }
    }
}
