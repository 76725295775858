.m-topbar {
    &.-management {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        @include media-down(m) {
            align-items: center;
            justify-content: space-between;
        }
    }

    &.-dashboard {
        @include media-up(m) {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }

        .m-topbar {
            &__title {
                padding-bottom: 0;
            }
        }
    }

    .m-imageUpload {
        margin-left: 1px;
    }

    &__title {
        @include media-up(s) {
            padding-bottom: 30px;
        }

        h1 {
            color: $dark-purple;
            font-family: "SofiaPro", sans-serif;
            font-weight: 600;
        }

        span {
            color: $gray-14;
            font-family: "SofiaPro-I", sans-serif;
        }
    }
    &__button {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: flex-end;
        margin: 25px 0;

        @include media-up(s) {
            margin-top: 0;
        }

        span {
            display: contents;
        }

        svg {
            margin-top: -7px;
            margin-right: 5px;
            cursor: pointer;
        }

        &.-filter {
            margin-right: 20px;
            color: $dark-purple;
            letter-spacing: -0.6px;
            line-height: 24px;
        }
    }

    &__select {
        margin: 35px 0 24px 0;

        @include media-up(s) {
            margin-top: 0;
        }

        .m-selectGroup {
            display: flex;
            flex-wrap: wrap;
            align-items: center;

            input {
                width: 375px;
            }

            label {
                margin-right: 12px;
            }
        }
    }
}
