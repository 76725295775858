.m-selectGroup {
    width: 100%;
    outline: 0;
    position: relative;
    height: auto;
    margin-bottom: 20px;

    ::-webkit-scrollbar {
        width: 6px;
    }

    ::-webkit-scrollbar-track {
        background-color: $gray-13;
        border-radius: 4px;
        opacity: 0.5;
    }

    ::-webkit-scrollbar-thumb {
        background: $purple-blue-light;
        border-radius: 4px;
    }

    input {
        width: 100%;
        cursor: pointer;
    }

    &.-required {
        span {
            color: $red;
        }
        label {
            &::after {
                position: relative;
                content: "*";
                color: $purple-blue;
                right: -5px;
            }
        }
    }

    &.-disabled {
        opacity: 0.7;

        label {
            color: #828a8d;
        }

        input {
            cursor: default;
            color: #828a8d;
            background-color: $white;
        }
    }

    &__container {
        position: relative;
        .a-chevron {
            position: absolute;
            top: 45%;
            right: 20px;
        }
    }

    &__select {
        &.-open {
            ~ .a-chevron {
                transform: rotate(225deg);
                margin-top: 2px;
            }

            &.-error {
                border: 1px solid $purple-blue-light;
            }
        }

        &.-hide {
            color: transparent;

            &::placeholder {
                visibility: hidden;
            }
        }

        &.-error {
            border: 1px solid $error-color;
        }
    }

    &__options {
        position: absolute;
        top: 50px;
        z-index: 2;
       // height: -moz-fit-content;
       // height: fit-content;
        max-height: 150px;
        width: 100%;
        opacity: 0;
        background-color: $white;
        border: 1px solid $purple-blue-light;
        border-radius: 4px;
        pointer-events: none;
        transition: opacity 0.3s, top 0.3s;
        overflow: hidden;
        overflow-y: auto;

        &.-open {
            opacity: 1;
            pointer-events: initial;
        }

        ul {
            background-color: $white;
            list-style: none;
            padding: 0;
            margin: 0;

            li {
                padding: 7px 10px;
                font-size: 13px;
                cursor: pointer;
                transition: 0.3s;

                &:hover {
                    background-color: rgba($purple-blue-light, 0.05);
                    transition: 0.3s;
                }
            }
        }
    }

    &__searchInput {
        width: 100%;
        background-color: transparent;
        outline: 0;
        border: 2px solid transparent;
        white-space: nowrap;
        position: absolute;
        top: 0;
        left: 0;
    }
}
