.m-confirmationModal {
  position: fixed;
  z-index: 20;
  left: 0;
  top: 0;
  display: grid;
  place-items: center;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.3);
  opacity: 0;
  pointer-events: none;
  transition: opacity .3s;

  &.-show {
    opacity: 1;
    pointer-events: initial;

    .m-confirmationModal__content {
      transform: translateY(0);

      .m-confirmationModal__buttons {
        display: flex;
        margin-top: 20px;
        .a-button{
          padding: 5px;
          &:not(:first-child){
            margin-left: 15px;
          }
        }
        .a-buttonText{
          width: auto;
          .a-icon-box {
            padding: 0px;
          }
        }
      }
    }
  }

  .wrapper {
    width: calc(100% - 40px);
    max-width: 500px;
    max-height: 80vh;
    margin: auto;
  }

  &__content {
    background-color: $white;
    padding: 20px 25px;
    overflow: auto;
    box-shadow: 0 4px 8px 0 rgba($black, 0.2), 0 6px 20px 0 rgba($black, 0.19);
    transition: transform .3s;
    border-radius: 6px;
    transform: translateY(-100%);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;

    &.-clickworker {
      overflow: inherit;
    }
  }
}