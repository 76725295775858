.m-sidenav {
    position: fixed;
    z-index: 2;
    top: 0;
    left: 0;
    height: 100vh;
    width: 355px;
    padding: 20px 10px;
    border-top: 0;
    border-left: 0;
    background-color: $purple-blue;
    transition: 0.5s;

    @include media-down(s) {
        width: 300px;
    }

    &__items {
        overflow-y: auto;
        overflow-x: hidden;
        height: 100%;
        padding-bottom: 50px;

        &::-webkit-scrollbar {
            width: 4px;
        }

        &::-webkit-scrollbar-track {
            background-color: $gray-13;
            border-radius: 4px;
            opacity: 0.5;
        }

        &::-webkit-scrollbar-thumb {
            background: $purple-blue-light;
            border-radius: 4px;
        }
    }
    &.-closed {
        width: 85px;

        .m-sidenav {
            &__itemLabel,
            &__logoLabel {
                opacity: 0;
                width: 0;
                color: $white;
            }

            &__items {
                .m-ctaEmail {
                    span {
                        opacity: 0;
                        width: 0;
                        color: $white;
                    }
                }
            }
            &__toggler {
                background-color: $purple-blue-light;
                border-color: $purple-blue-light;

                &::after {
                    left: calc(50% - 2px);
                    border-color: white;
                    transform: translate(-50%, -50%) rotate(-135deg);
                }
            }

            &__itemContainer {
                &.-active {
                    .m-sidenav__subitem {
                        &::before {
                            opacity: 0;
                            transition: opacity 0.5s ease-out;
                        }
                    }
                }
            }

            &__subItemsIcon {
                display: none;
            }

            &__subitem {
                padding-left: 10px;
                transition: max-height 0.5s, opacity 0.3s, padding-left 0.3s,
                    color 0.3s ease-out;

                svg {
                    opacity: 1;
                    width: 24px;
                    transition: width 0.5s, opacity 0.5s;
                }
            }

            &__subitemLabel {
                opacity: 0;
                width: 0;
                transition: width 0.5s, opacity 0.5s;
                display: none;
            }

            &__subitems {
                padding-left: 10px;
            }
        }
    }

    &__scroll {
        overflow-y: auto;
        overflow-x: hidden;
        height: 100%;
        padding-top: 20px;
    }

    &__item {
        position: relative;
        height: 54px;
        width: 335px;
        outline: 0;
        transition: width 0.5s;
        border-radius: 8px;
        background-color: $purple-blue;

        &.-active {
            background-color: $purple-blue-light;

            .m-sidenav__subItemsIcon {
                &::after {
                    transform: translate(-50%, -50%) rotate(135deg);
                }
            }
        }

        &:hover {
            background-color: $purple-light;
            transition: 0.3s;

            .m-sidenav__itemLabel {
                color: $white;
            }
        }

        svg {
            min-width: 24px;
            transition: stroke 0.3s;
        }
    }

    &__itemLabel {
        overflow: hidden;
        width: 100%;
        margin: 5px 0 0 5px;
        white-space: nowrap;
        color: $white;
        font-size: 16px;
        letter-spacing: -0.4px;
        line-height: 24px;
        opacity: 1;
        transition: opacity 0.5s, padding-right 0.3s, width 0.5s, color 0.3s;
    }

    &__toggler {
        position: absolute;
        right: -17px;
        top: 15px;
        z-index: 2;
        display: block;
        height: 48px;
        width: 32px;
        border: 1px solid $purple-blue-light;
        border-radius: 8px;
        background-color: $purple-blue-light;
        transition: 0.5s;
        box-shadow: 0 10px 20px -9px $purple-blue-light;
        cursor: pointer;

        &::after {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            display: block;
            width: 7px;
            height: 7px;
            border: 1.5px solid white;
            border-top: 0;
            border-right: 0;
            transform: translate(-50%, -50%) rotate(45deg);
            transition: transform 0.5s, left 0.5s, border-color 0.5s;
        }
    }

    &__item,
    &__group {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        cursor: pointer;
        -webkit-user-select: none;
        user-select: none;
        padding: 12px 15px;
        color: white;
        transition: 0.5s;

        &__label {
            text-decoration: none;
            font-size: 14px;
            line-height: 40px;
            letter-spacing: -0.2;
        }
    }

    &__item {
        width: 100%;
        transition: background-color 0.5s, color 0.5s;
    }

    &__logo {
        position: relative;
        display: flex;
        align-items: center;
        height: 40px;
        margin-bottom: 20px;
        padding: 0 10px;
        outline: 0;

        svg {
            min-width: 24px;
            transition: stroke 0.3s;
        }
    }
    &__logoBox {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 260px;

        &--icon {
            background-image: url(../../../images/ASAlogo.svg);
            height: 33px;
            width: 45px;
            background-size: cover;
            position: relative;
            .a-icon-box {
                left: 0% !important;
            }
        }

        &--title {
            display: block;
            margin: 7px 0 6px 15px;
            color: $white;
            font-size: 13px;
            font-family: "ProximaNova-EB", sans-serif;
            transition: opacity 0.5s, padding-right 0.3s, width 0.5s, color 0.3s;
            @include media-up(l) {
                font-size: 17px;
                white-space: nowrap;
            }
            span {
                font-family: "ProximaNova-L", sans-serif;
            }
        }
    }

    &__logoLabel {
        margin-left: 10px;
        color: $white;
        font-size: 16px;
        letter-spacing: -0.5px;
        line-height: 24px;
        transition: opacity 0.5s, padding-right 0.3s, width 0.5s, color 0.5s;
    }

    &__subItemsIcon {
        position: absolute;
        right: 30px;
        transition: background-color 0.5s, border-color 0.5s;
        cursor: pointer;

        &::after {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            display: block;
            transform: translate(-50%, -50%) rotate(-45deg);
            width: 7px;
            height: 7px;
            border: 1.5px solid white;
            border-top: 0;
            border-right: 0;
            transition: transform 0.5s, left 0.5s, border-color 0.5s;
        }
    }

    &__itemContainer {
        display: flex;
        flex-direction: column;

        &.-active {
            height: max-content;

            .m-sidenav__subitem {
                opacity: 1;
                max-height: 30px;
                margin-top: 8px;
                transition: max-height 0.5s, opacity 0.3s, color 0.3s ease-in;

                &::before {
                    opacity: 1;
                    transition: opacity 0.5s ease-in;
                }
            }

            .m-sidenav {
                &__subItemsIcon {
                    &::after {
                        transform: translate(-50%, -50%) rotate(135deg);
                        transition: 0.3s;
                    }
                }
            }
        }
    }

    &__subitems {
        display: flex;
        flex-direction: column;
        padding-left: 50px;
        transition: padding-left 0.3s;
        background-color: $purple-blue;
        border-radius: 8px;
    }

    &__subitem {
        display: flex;
        align-items: center;
        position: relative;
        max-height: 0;
        padding-left: 16px;
        opacity: 0;
        transition: max-height 0.5s, opacity 0.3s, padding-left 0.3s,
            color 0.3s ease-out;
        white-space: nowrap;
        cursor: pointer;

        &::before {
            content: "";
            position: absolute;
            top: 50%;
            left: 0;
            display: block;
            transform: translate(0, -50%) rotate(-135deg);
            width: 5px;
            height: 5px;
            border: 1.5px solid $purple-blue-light;
            border-top: 0;
            border-right: 0;
            opacity: 0;
            transition: opacity 0.5s ease-out;
        }

        svg {
            opacity: 0;
            width: 0;
            stroke: $purple-blue-light;
            transition: width 0.5s, opacity 0.5s;
        }
    }

    &__subitemLabel {
        display: block;
        width: auto;
        color: $light-purple;
        font-size: 16px;
        letter-spacing: -0.4px;
        line-height: 30px;
        opacity: 1;
        transition: color 0.5s, opacity 0.5s;

        &:hover {
            color: $white;
            transition: 0.3s;
        }
    }
    &__divider {
        background-color: rgba($purple-blue-light, 0.5);
        width: 95%;
        min-height: 1px;
        margin: 5px 0;
    }
}
