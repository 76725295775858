.m-workingTable {
    &__header {
        margin: 0 15px;
        color: $black;
        background-color: $purple-blue-light;
        border-radius: 4px;

        &--field {
            .a-header {
                width: 100%;
                padding: 12px 12px 12px 0;
                color: $white;
                background-color: $purple-blue-light;
                border-radius: 4px;
            }
        }

        @include media-down(l) {
            display: none;
        }
    }

    .m-tableTime {
        &__day {
            margin: 15px 0;
            padding: 15px 0;
            border-radius: 4px;

            @include media-down(l) {
                margin: 35px 0;
                border: 1px solid rgba($purple-blue-light, 0.2);
                box-shadow: $working-shadow;
            }
            @include media-up(l) {
                button {
                    margin: 25px 0 0 10px;
                }
            }

            @include media-up(l) {
                padding: 0;
                background-color: transparent;
                button {
                    margin: 0;
                }
            }

            &--input {
                margin-bottom: 15px;

                @include media-up(l) {
                    margin-bottom: 0;
                }

                label {
                    @include media-up(l) {
                        display: none;
                    }
                }

                button {
                    width: 100%;
                }
            }
        }

        .a-addTime {
            margin: 10px 15px;

            button {
                width: 100%;
                background-color: transparent;
                border: 1px solid $purple-blue-light;
                transition: 0.3s;

                span {
                    &::before {
                        content: "+";
                        margin-right: 10px;
                    }
                }

                &:hover {
                    background-color: $purple-blue-light;
                    transition: 0.3s;
                }
            }
        }
    }
}
