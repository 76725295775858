.m-main {
    position: relative;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    transition: 0.5s;

    .m-mainButton {
        float: right;
        margin-right: 0;
    }

    &.-sidebarIsOpen {
        transition: 0.5s;

        @include media-up(m) {
            width: calc(100vw - 210px);
            margin-left: 210px;
        }

        @include media-up(l) {
            width: calc(100vw - 270px);
            margin-left: 270px;
        }
    }

    @include media-up(l) {
        margin-top: 0;
        min-height: 100vh;
    }

    &__wrapper {
        display: flex;
        justify-content: center;
        width: 95%;
        height: 100%;
        margin: 20px auto;
        padding: 10px 0;

        @include media-up(m) {
            width: 90%;
            padding-left: 65px;
        }

        ._wr {
            margin: 0;

            @include media-up(xxl) {
                margin: inherit;
            }
        }
    }

    &__content {
        width: 100%;

        form {
            margin-top: 30px;

            .a-button {
                margin-top: 25px;
                &.-primary {
                    padding: 12px 25px 4px;
                    span {
                        font-size: 14px;
                    }
                }
            }
        }

        @include media-up(m) {
            margin-left: 20px;
        }

        @include media-up(xl) {
            margin-left: 0;
        }

        @include media-down(l) {
            padding: 0;
        }

        .m-noFilter {
            .m-topbar {
                &__button {
                    button {
                        @include media-down(l) {
                            margin-left: 20;
                        }
                    }
                }
            }
        }

        .m-modal {
            position: absolute;
            top: 250%;
            left: 55%;
            transform: translate(-50%, -50%);
            z-index: 299;
            width: 80%;
            height: 75vh;
            background-color: $white;
            border: 1px solid $purple-blue-light;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 20px;
            border-radius: 12px;

            @include media-down(m) {
                left: 50%;
            }

            @include media-up(l) {
                top: 50%;
                left: 55%;
            }
            @include media-up(xl) {
                left: 50%;
            }

            .m-content {
                width: 50%;
                margin: 20px auto;
                @include media-down(m) {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                }
                &__row {
                    display: flex;
                    justify-content: flex-end;
                }
                .m-loginForm__form--row {
                    margin: 30px auto;
                }
            }
        }
    }
}
