.m-textarea {
    textarea {
        width: 100%;
        padding: 12px 20px;
        color: $light-blue;
        border: 1px solid $light-blue-2;
        background-color: rgba($white, 0.4);
        filter: none;
        resize: none;

        &:focus {
            outline: 1px solid $purple-blue-light;
        }
    }
}
