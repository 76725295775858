// Use $font-path from _vars/_base.scss as path to fonts folder or update it here
// Use https://transfonter.org/ to generate fonts and update font path here
// Default font path (relative to style.css) is ../fonts (that is value of $font-path)

@font-face {
    font-family: "SofiaPro";
    src: url("#{$font-path}/SofiaPro/SofiaProRegular.woff") format("woff");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "SofiaPro-B";
    src: url("#{$font-path}/SofiaPro/SofiaProBold.woff") format("woff");
    font-weight: 400;
    font-style: bold;
}

@font-face {
    font-family: "SofiaPro-I";
    src: url("#{$font-path}/SofiaPro/SofiaProRegular-Italic.woff")
        format("woff");

    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "SofiaPro-M";
    src: url("#{$font-path}/SofiaPro/SofiaProMedium.woff") format("woff");
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: "ProximaNova-I";
    src: url("#{$font-path}/ProximaNova/ProximaNova-RegularIt.woff")
        format("woff");
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: "ProximaNova-EB";
    src: url("#{$font-path}/ProximaNova/ProximaNova-Extrabld.woff")
        format("woff");
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: "ProximaNova-L";
    src: url("#{$font-path}/ProximaNova/ProximaNova-Light.woff") format("woff");
    font-weight: 700;
    font-style: normal;
}
