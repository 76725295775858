.m-ctaEmail {
    margin: 20px 10px;
    display: flex;
    flex-wrap: wrap;
    border-top: 1px solid rgba($purple-blue-light, 0.5);
    // border-bottom: 1px solid #7d9da2;

    a {
        padding: 7px 5px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        cursor: pointer;
        user-select: none;
        margin: 10px 0;
        background-color: $purple-blue-light;
        border-radius: 4px;
        color: $white;
        overflow: hidden;
        width: 100%;
        white-space: nowrap;

        &:hover {
            background-color: rgba($purple-blue-light, 0.7);
            box-shadow: $button-shadow;
            transition: 0.3s;
        }
        span {
            padding: 6px 0 0 5px;
            opacity: 1;
            transition: opacity 0.5s, padding-right 0.3s, width 0.5s, color 0.3s;
        }
    }
}
