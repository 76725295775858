.a-icon-box {
    display: flex;
    justify-content: center;
    align-items: baseline;
    padding: 2px;

    &.-square {
        border-radius: 0;
    }

    &.-circle {
        padding: 4px;
        border-radius: 50%;
    }
}
