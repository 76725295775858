// Framework grid generation
//
// any value of `$grid-columns`.
@mixin make-grid-columns(
    $columns: $grid-columns,
    $gutter: $grid-gutter-width,
    $breakpoints: $grid-breakpoints
) {
    // Common properties for all breakpoints
    %grid-column {
        position: relative;
        width: 100%;
        min-height: 1px; // Prevent columns from collapsing when empty
        padding-right: ($gutter / 2);
        padding-left: ($gutter / 2);
    }

    @each $breakpoint in map-keys($breakpoints) {
        $infix: breakpoint-infix($breakpoint, $breakpoints);

        // Allow columns to stretch full width below their breakpoints
        @for $i from 1 through $columns {
            ._#{$infix}#{$i} {
                @extend %grid-column;
            }
        }

        ._#{$infix},
        ._#{$infix}_auto {
            @extend %grid-column;
        }

        @include media-up($breakpoint, $breakpoints) {
            // Provide basic `._{bp}` classes for equal-width flexbox columns
            ._#{$infix} {
                flex-basis: 0;
                flex-grow: 1;
                max-width: 100%;
            }

            ._#{$infix}_auto {
                flex: 0 0 auto;
                width: auto;
                max-width: none; // Reset earlier grid tiers
            }

            @for $i from 1 through $columns {
                ._#{$infix}#{$i} {
                    @include col($i, $columns);
                }
            }

            .ord_#{$infix}_first {
                order: -1;
            }
            .ord_#{$infix}_last {
                order: $columns + 1;
            }

            @for $i from 0 through $columns {
                .ord_#{$infix}#{$i} {
                    order: $i;
                }
            }

            // `$columns - 1` because offsetting by the width of an entire row isn't possible
            @for $i from 0 through ($columns - 1) {
                @if not($infix == "" and $i == 0) {
                    // Avoid emitting useless .offset-0

                    .ofs_#{$infix}#{$i} {
                        @include make-col-offset($i, $columns);
                    }
                }
            }
        }
    }
}
