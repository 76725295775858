.m-workingTime {
    ~ .m-mainButton {
        width: 250px;
        button {
            width: 100%;
            margin-right: 15px;
        }
    }
    &__header {
        margin: 30px 0 15px 0;
        &--field {
            margin-bottom: 15px;
            @include media-up(l) {
                margin-bottom: 0;
            }
        }
    }

    .a-button {
        &.-primary {
            width: 200px;
        }
    }
}
