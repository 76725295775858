.a-avatarBox {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5px 5px 5px 10px;

    &__img {
        position: relative;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        &.-large {
            width: 48px;
            height: 48px;
        }
        &.-medium {
            width: 28px;
            height: 28px;
        }
        &.-small {
            width: 24px;
            height: 24px;
        }
        &.-tiny {
            width: 16px;
            height: 16px;
        }
        &.-outline {
            outline: 1px solid rgb(194, 191, 191);
            outline-offset: 2px;
        }
        .a-avatarImage {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 50%;
        }
    }

    &__user {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: left;
        margin-left: 10px;
        margin-top: 5px;
        min-width: 100px;

        &__name {
            display: block;

            &.-large {
                font-size: 1rem;
            }
            &.-medium {
                font-size: 0.9rem;
            }
            &.-small {
                font-size: 0.8rem;
            }
            &.-tiny {
                font-size: 0.7rem;
            }
        }
        &__job {
            font-size: 12px;
        }
    }
}

.m-avatar {
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;

    &__popup {
        position: absolute;
        top: 60px;
        z-index: 10;
        display: flex;
        flex-direction: column;
        width: max-content;
        min-width: 200px;
        padding: 10px;
        transform: translateY(-20px);
        background-color: $white;
        box-sizing: border-box;
        border: 1px solid rgba($black, 0.2);
        border-radius: 10px;
        opacity: 0;
        pointer-events: none;
        animation-name: show;
        animation-duration: 0.3s;
        transition: opacity 0.3s, transform 0.3s;
        align-items: center;
        right: -35px;

        .m-avatar {
            &__item {
                display: inline-block;
                width: 100%;
                margin-bottom: 5px;
                padding: 0.5rem;
                color: $white;
                background-color: $purple-blue-light;
                border: 1px solid $purple-blue-light;
                text-align: center;
                border-radius: 5px;
                font-size: 0.9rem;
                transition: 0.3s;

                &:hover {
                    background-color: $purple-blue;
                    box-shadow: $button-shadow;
                    transition: 0.3s;

                    svg {
                        stroke: $white;
                    }
                }

                &.-profile {
                    color: $purple-blue-light;
                    background-color: transparent;

                    &:hover {
                        color: $white;
                        background-color: $purple-blue;
                        box-shadow: none;
                        transition: 0.3s;
                    }
                    .a-account {
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: center;

                        .a-icon-box {
                            margin: 0 5px 0 0;
                        }

                        span {
                            padding-top: 1px;
                        }
                    }
                }
            }
        }

        &.-open {
            transform: translateY(0);
            opacity: 1;
            pointer-events: initial;
        }
        &::before {
            content: "";
            position: absolute;
            top: -5px;
            right: 40px;
            display: block;
            width: 7px;
            height: 7px;
            background-color: $white;
            border: 1px solid rgba($black, 0.2);
            border-right: 0;
            border-bottom: 0;
            transform: rotate(45deg);
        }
    }
}
