.m-headerMain {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    padding: 10px 15px;
    padding-left: 120px;
    background-color: $white;
    box-shadow: 0 4px 5px -3px rgba($black, 0.3);
    transition: 0.5s;

    @include media-down(l) {
        padding: 5px 0 0;
    }

    @include media-up(xl) {
        padding-left: 135px;
    }

    @include media-down(l) {
        flex-direction: column-reverse;
    }

    &__search {
        width: 80%;
        margin-left: 10%;
        margin-bottom: 10px;

        @include media-up(l) {
            width: 30%;
            margin-left: 10px;
            margin-bottom: 0;
        }

        @include media-up(xl) {
            width: 25%;
            margin-left: 15px;
        }
        .m-inputGroup {
            margin-bottom: 0;
            input {
                border-radius: 8px;
            }
        }
    }

    &__icons {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        @include media-down(l) {
            justify-content: flex-end;
            width: 85%;
            padding-left: 7%;
            margin: 16px auto;
        }

        @include media-down(s) {
            justify-content: flex-start;
            margin-left: 17%;
            padding-left: 0;
        }

        &--wrapper {
            position: relative;
            display: flex;
            justify-content: space-around;
            align-items: center;
            min-width: 50px;
            margin-right: 30px;

            @include media-down(l) {
                margin: 0 5px;
            }

            @include media-down(s) {
                margin: 0;
            }

            .a-icon-box {
                position: unset;
                width: auto;
                margin: 0 auto;
                padding: 0;
                cursor: pointer;
                transform: unset;

                @include media-down(s) {
                    width: 30px !important;
                    margin: 0 auto;
                }
            }

            .a-avatarBox {
                &__img {
                    @include media-down(s) {
                        outline-offset: 0;
                    }
                }

                &__user {
                    text-align: left;

                    &__name {
                        font-size: 1.125rem;
                        letter-spacing: -0.8px;
                        line-height: 28px;

                        @include media-down(s) {
                            font-size: 0.9rem !important;
                        }
                    }

                    &__job {
                        &.-jobTitle {
                            margin-top: -5px;
                            color: $light-blue;
                        }
                    }
                }
            }

            .m-selectGroup {
                margin-bottom: 0;
                input {
                    width: 85px;
                    padding: 12px 0 10px 12px;
                    border: 1px solid $gray-13;
                    border-radius: 8px;

                    &:focus {
                        outline: 1px solid $gray-13;
                    }
                }

                &__options {
                    top: 50px;
                    border: 1px solid $gray-13;
                }
            }
        }
    }

    .m-selectGroup {
        &.-languagePicker {
            li {
                font-size: 14px;
                text-transform: capitalize;
            }

            .m-selectGroup__select {
                text-transform: capitalize;
            }
        }
    }
}
