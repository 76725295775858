.m-inputGroup {
    position: relative;
    margin-bottom: 25px;

    input {
        width: 100%;
    }

    &__error {
        position: absolute;
        bottom: -20px;
        left: 0;
        opacity: 0;
        color: $error-color;
        font-size: 13px;
        transition: 0.3s;
        line-height: 16px;
        white-space: nowrap;

        &.-active {
            opacity: 1;
        }
    }

    &.-error {
        input {
            border: 1px solid $error-color;

            &:focus {
                border: 1px solid $purple-blue-light;
                outline: none;
            }
        }
    }

    &.-icon {
        position: relative;

        input {
            margin-bottom: 0;
            &::placeholder {
                color: $light-blue;
                font-family: "ProximaNova-I", sans-serif;
                font-size: 14px;
                letter-spacing: -0.5px;
                line-height: 20px;
            }
        }

        .a-icon-box {
            position: absolute;
            top: 50%;
            right: 2%;
            left: auto;
            width: auto;
            padding: 7px;
            background-color: $purple-blue-light;
            border-radius: 8px;
            box-shadow: $box-shadow;
            transform: translateY(-50%);
            cursor: pointer;

            svg {
                stroke: $white;
            }
        }
    }
}
