.m-noAuth {
    // margin-top: calc(100vh - 85vh);

    .m-loginForm {
        width: 100%;

        h2 {
            padding: 20px;
        }

        &__form {
            &--row {
                padding: 20px;

                .a-text,
                .a-button {
                    height: 58px;
                }
            }
        }
    }
}
