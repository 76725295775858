.a-chevron {
    display: inline-block;
    width: 8px;
    height: 8px;
    margin-top: -3px;
    margin-left: 15px;
    color: $light-blue;
    border: 1.5px solid $gray-200;
    border-top-color: transparent;
    border-left-color: transparent;
    transition: transform 0.3s, border-color 0.3s, margin-top 0.3s;
    cursor: pointer;

    &.-up {
        border-right-color: $purple-blue-light;
        border-bottom-color:$purple-blue-light;
        transform: rotate(225deg);
    }

    &.-down {
        border-right-color: $light-blue;
        border-bottom-color: $light-blue;
        transform: rotate(45deg);
    }
}
