.m-dateTimePicker {
    input {
        width: 100%;
        background-color: $white;
    }

    &.-error {
        input {
            border: 1px solid $error-color;

            &:focus {
                border: 1px solid $purple-blue-light;
                outline: none;
            }
        }

        &.-rangeError {
            position: relative;
        }
    }

    &.-disabled {
        opacity: 0.5;
    }
}

.flatpickr-calendar {
    .flatpickr-hide-minutes {
        display: none;
    }

    &.noCalendar {
        height: 70px;

        .flatpickr-time {
            width: 100%;
            margin-top: 29px;
            max-height: none !important;
            border: none !important;
            line-height: 30px !important;

            input {
                transition: 0.3s;
                &:hover {
                    background-color: $white !important;
                    transition: 0.3s;
                }
                &:focus {
                    background-color: $white !important;
                }
            }

            &::before {
                content: "Hours";
                position: absolute;
                top: -1px;
                width: 50%;
                height: 30px;
                padding-left: 11px;
                color: $white;
                background-color: $purple-blue-light;
                border-radius: 6px 0 0 0;
                text-align: center;
            }

            &::after {
                content: "Minutes";
                position: absolute;
                top: -1px;
                right: 0;
                width: 50%;
                height: 30px;
                padding-left: 11px;
                color: $white;
                background-color: $purple-blue-light;
                border-radius: 0 6px 0 0;
                text-align: center;
            }
        }
    }
    &.arrowTop {
        &::after {
            border-bottom-color: $purple-blue-light !important;
        }
    }
    .flatpickr-month {
        padding-top: 2px;
        background-color: $purple-blue-light !important;
        border-radius: 4px 4px 0 0 !important;

        .flatpickr-monthDropdown-months {
            background-color: $purple-blue-light !important;

            .flatpickr-monthDropdown-month {
                background-color: $gray-14 !important;

                &:hover {
                    background-color: $gray-13 !important;
                    transition: 0.3s;
                }
            }
        }
    }

    .flatpickr-next-month {
        &:hover {
            svg {
                fill: $gray-13 !important;
                transition: 0.3s;
            }
        }
    }
    .flatpickr-innerContainer {
        // display: none;
        .flatpickr-weekdays {
            background-color: $purple-blue-light !important;

            .flatpickr-weekday {
                color: $white !important;
                background-color: $purple-blue-light !important;
            }
        }

        .flatpickr-days {
            .flatpickr-day {
                margin-top: 5px;
                border-radius: 4px !important;
                &.prevMonthDay {
                    color: rgba(72, 72, 72, 0.3);
                    background-color: transparent;
                }
                &.nextMonthDay {
                    color: rgba(72, 72, 72, 0.3);
                    background-color: transparent;
                }

                &.today {
                    transition: 0.3s;

                    &:hover {
                        color: $purple-blue;
                        border: 1px solid transparent;
                        transition: 0.3s;
                    }
                }

                &.selected {
                    color: $gray-13;
                }
                &:hover {
                    background-color: $gray-13 !important;
                    border: 1px solid transparent;
                    transition: 0.3s;
                }
                &.selected {
                    background-color: $purple-blue-light !important;
                    border: none;
                }
            }
        }
    }
}
.flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n + 1)),
.flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n + 1)),
.flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n + 1)) {
    background-color: $purple-blue-light !important;
}
.flatpickr-day.endRange {
    background-color: $purple-blue-light !important;
    border-color: transparent !important;
}

.flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n + 1)),
.flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n + 1)),
.flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n + 1)),
.flatpickr-day.inRange {
    box-shadow: -5px 0 0 $light-purple, 5px 0 0 $light-purple !important;
}
.flatpickr-day.inRange {
    background-color: $light-purple !important;
    border-color: transparent !important;
}
.flatpickr-monthSelect-month.selected {
    background-color: $light-purple !important;
}

.flatpickr-monthSelect-theme-dark {
    .flatpickr-months {
        .flatpickr-month {
            border-radius: 4px !important;
        }
    }

    .flatpickr-innerContainer {
        display: none;
    }
}
