.m-login {
    .m-loginForm {
        height: auto;
        padding: 20px;
        margin-top: 80px;

        &__logo {
            display: flex;
            justify-content: flex-start;
            margin-bottom: 80px;
            margin-left: 10px;

            &--icon {
                background-image: url(../../../images/ASAlogoBlueH.svg);
                height: 40px;
                width: 70px;
                background-size: contain;
                background-repeat: no-repeat;
            }

            &--title {
                padding-top: 5px;
                white-space: nowrap;
                font-size: 18px;
                font-family: "ProximaNova-EB", sans-serif;

                span {
                    font-family: "ProximaNova-L", sans-serif;
                }
            }
        }

        @include media-up(m) {
            padding: 0;
            margin: 100px 30px;
        }

        h2 {
            font-size: 1.7rem;
        }

        p {
            font-size: 1rem;
            color: $gray-200;
        }

        &__form {
            position: relative;
            width: 100%;

            &--row {
                padding: 10px 0;

                button {
                    width: 100%;
                }

                .m-inputGroup {
                    .a-icon-box {
                        right: 3%;
                        top: 30px;
                        transform: translate(0);
                    }
                }

                a {
                    text-decoration: none;
                    font-size: 0.8rem;
                }

                &--forgotPassword {
                    display: flex;
                    justify-content: center;
                    width: 100%;

                    span,
                    a {
                        width: auto;
                        color: $purple-blue-light;
                        font-size: 0.9rem;
                        transition: 0.3s;

                        &:hover {
                            color: $gray-14;
                            transition: 0.3s;
                        }
                    }

                    a {
                        margin: 0 10px;
                    }
                }

                &.-line {
                    position: relative;
                    display: flex;
                    justify-content: space-between;
                    margin: 20px 0;

                    span.m-text-line {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        float: left;
                        width: 40%;
                        margin: auto;
                        color: $gray-200;
                        font-size: 12px;
                        transform: translate(-50%, -50%);
                        background-color: $gray-12;
                        text-align: center;
                    }

                    span.m-hr-line {
                        display: block;
                        margin: 9px auto;
                        width: 100%;
                        height: 1px;
                        background-color: $gray-200;
                    }
                }

                .a-password::placeholder,
                .a-text::placeholder {
                    color: rgb(212, 212, 212);
                }

                .a-errorMessage {
                    color: $error-color;
                    font-size: 12px;
                    margin-left: 10px;
                }
            }
        }
    }

    &__image {
        position: absolute;
        right: 0;
        height: 100vh;
        background-image: url(../../../images/login.jpeg);
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;

        @include media-down(l) {
            display: none;
        }
    }
}
