.m-fieldArray {
    &__remove {
        margin-top: 6px;
        .a-button.-secondary {
            width: 100%;
            margin-top: 20px;
        }
    }

    .a-addTime {
        button {
            width: 100%;
        }
    }

    &__error {
        color: $error-color;
        text-align: center;
        margin-top: 25px;
    }
}
