body {
    color: $purple-light;
    overflow-x: hidden;

    &::-webkit-scrollbar {
        width: 6px;
    }

    &::-webkit-scrollbar-track {
        background-color: $gray-13;
        border-radius: 4px;
        opacity: 0.5;
    }

    &::-webkit-scrollbar-thumb {
        background: $purple-blue-light;
        border-radius: 4px;
    }

    input {
        padding: 13px 0 10px 12px;
        color: $dark-blue;
        background-color: $white;
        border: 1px solid $light-blue-2;
        border-radius: 4px;
        font-size: 14px;

        &:focus {
            outline: 1px solid $purple-blue-light;
        }
    }

    label {
        font-size: 14px;
        .a-require-star {
            color: $purple-blue;
        }
    }

    textarea {
        height: 144px;
        font-size: 13px;
        border-radius: 4px;
    }

    button {
        &:focus {
            outline: none;
        }
    }
}
