.a-errorMessage {
    margin-top: 5px;
    color: $error-color;
    font-size: 13px;
    line-height: 16px;

    &.-reportError {
        position: absolute;
        top: -55%
    }
}
