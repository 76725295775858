.m-dashboard {
    @include media-down(l) {
        margin-bottom: 20px;
    }

    &__card {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        height: 100%;
        padding: 20px 20px;
        background-color: $white;
        border-radius: 8px;

        &.-reportSummary {
            flex-wrap: nowrap;
            margin-top: 20px;
            border: 1px solid $light-blue-2;
            padding: 5px 10px 0px;
            height: auto;
            border-radius: 4px;

            @include media-up(l) {
                margin-top: 0;
            }

            p {
                font-size: 16px;
                margin-bottom: 0;
            }
            .a-icon-box {
                margin-right: 10px;
            }

            .m-dashboard {
                &__card {
                    &--info {
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        width: auto;

                        p {
                            margin-right: 15px;
                        }
                    }
                }
            }
        }

        .a-icon-box {
            margin-right: 25px;
            margin-bottom: 5px;
        }
        &.-user {
            .a-icon-box {
                background: linear-gradient(180deg, #f4effc 0%, #fdfcfe 100%);
                border-radius: 50%;
                padding: 12px;
                svg {
                    background-color: #905ed8;
                    border-radius: 50%;
                    padding-top: 5px;
                }
            }
        }

        &.-visits,
        &.-visitsMinus {
            .a-icon-box {
                border-radius: 50%;
                padding: 12px;
                background: linear-gradient(180deg, #e1f2ff 0%, #fbfdff 100%);

                svg {
                    background-color: #68acfd;
                    border-radius: 50%;
                }
            }

            .m-dashboard__card--info {
                &.-visitNumber {
                    white-space: nowrap;
                }
            }
        }

        &.-visits {
            .m-dashboard__card--info {
                .a-icon-box {
                    background: #edfaee;
                }

                svg {
                    stroke: #66c07f;
                    transform: rotate(180deg);
                }
            }
        }

        &.-dimensions {
            .a-icon-box {
                background: linear-gradient(180deg, #faebfe 0%, #fefbff 100%);
                border-radius: 50%;
                padding: 12px;
            }

            p {
                line-height: 16px;
            }
        }

        &.-daily {
            .a-icon-box {
                background: linear-gradient(180deg, #fff6de 0%, #fffefe 100%);
                border-radius: 50%;
                padding: 12px;
            }
        }

        &.-maxVisits {
            .a-icon-box {
                background: linear-gradient(180deg, #d8f3db 0%, #fefffe 100%);
                border-radius: 50%;
                padding: 12px;
            }
        }

        &.-minVisits {
            .a-icon-box {
                background: linear-gradient(180deg, #ffebea 0%, #fffefe 100%);
                border-radius: 50%;
                padding: 12px;
            }
        }

        &--info {
            width: 100px;

            @include media-up(xl) {
                width: 170px;
            }
            p {
                margin-bottom: 5px;
                color: $gray-17;
                font-family: "SofiaPro", sans-serif;
                font-size: 13px;
                letter-spacing: -0.4px;
                line-height: 20px;
            }

            span {
                font-size: 24px;
                font-weight: 600;
                letter-spacing: -0.8px;
                line-height: 32px;
            }

            &.-visitNumber {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                margin-left: 0;

                .a-icon-box {
                    margin-left: 8px;
                    margin-right: 0;
                    background: linear-gradient(
                        180deg,
                        #ffebea 0%,
                        #fffefe 100%
                    );
                    border-radius: 50%;
                    padding: 4px;

                    svg {
                        background: none;
                    }
                }

                .a-percentage {
                    margin-left: 5px;
                    color: #f86156;
                    font-size: 16px;
                    line-height: 20px;
                    font-weight: normal;
                }
            }
        }
    }

    &__table {
        .m-table {
            margin-top: 0;
            .m-pagination {
                display: none;
            }

            &__container {
                border-radius: 0 0 8px 8px;
            }
            table {
                tbody {
                    tr {
                        td {
                            padding: 11px 15px;
                            text-align: left;
                        }
                    }
                }
            }

            .a-icon-box {
                display: none;
            }

            .a-thead-title {
                span {
                    font-size: 14px;
                    font-weight: 600;
                }
            }
        }
        &--title {
            margin-top: 30px;
            background-color: $white;
            border-radius: 8px 8px 0 0;
            padding: 20px 0 5px 20px;

            p {
                font-size: 16px;
                font-weight: bold;
                line-height: 26px;
            }
        }
    }

    &__adminTable {
        .m-table {
            margin-top: 0;
            .m-pagination {
                display: none;
            }
        }
        &--title {
            margin-top: 30px;
            background-color: $white;
            border-radius: 8px 8px 0 0;
            padding: 20px 0 5px 20px;

            p {
                font-size: 16px;
                font-weight: bold;
                line-height: 26px;
            }
        }
    }
}
