.a-alert {
    position: fixed;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 200px;
    padding: 15px 20px;
    color: $white;
    background-color: rgba($default-color, 0.8);
    border-radius: 8px;
    font-size: 18px;
    line-height: 28px;
    opacity: 1;
    cursor: pointer;

    &.-default {
        background-color: $default-color;
    }

    &.-success {
        background-color: rgba($purple-blue-light, 0.8);
    }

    &.-error {
        background-color: $error-color;
    }

    &.-center {
        top: 50px;
        left: 50%;
        transform: translateX(-50%);
    }

    &.-right {
        top: 50px;
        right: 10%;
    }

    &.-left {
        top: 20px;
        right: 20px;
    }

    svg {
        margin-right: 10px;
    }
}
